export const ar = {
  auth: {
    login: 'تسجيل الدخول',
    logout: 'تسجيل الخروج',
    email: 'البريد الإلكترونى',
    rememberMe: 'تذكر حسابى',
    signIn: 'تسجيل الدخول',
    password_placeholder: 'ادخل رقمك السري',
    mail_massage: 'هذا البريد الإلكترونى غير صالح',
    mail_req_massage: 'برجاء إدخال بريدك الإلكترونى',
    password: 'كلمة المرور',
    forgetPassword: 'لا اتذكر كلمة المرور',
    Forget_Password: 'لا اتذكر كلمة المرور',
    sammary: 'برجاء إدخال البريد الإلكترونى لتتمكن من تعيين كلمة المرور',
    passwordMassage: 'برجاء إدخال كلمة المرور',
    backToSignin: 'الرجوع الي تسجيل الدخول',
    sendMail: 'إرسال بريد الكتروني',
    sorry403: 'نأسف ، لكن ليس لديك حق الوصول إلى هذه الصفحة أو المورد.',
    sorry505: 'واجه الخادم خطأ داخليًا أو خطأ في التكوين وكان كذلك لا يمكن إكمال طلبك',
    resetSent: 'تم إرسال كود إعادة التعيين بنجاح',
    plaeaseEnterMail: 'برجاء إدخال بريدك الإلكتروني ثم انقر فوق الزر أدناه لطلب واحد آخر.',
    confirmPassword: 'تأكيد كلمة المرور الجديدة',
    restPass_placeholder: 'اعد كتابه رقم المرور',
    confirm_massage: 'الرجاء تاكيد كلمة المرور',
    Reset: 'إعادة تعيين',
    cancle: 'الغاء',
    CheckInpoxp1: 'يرجى التحقق من مجلد البريد الوارد أو البريد العشوائي في',
    CheckInpoxp2: 'للحصول على رابط لإعادة تعيين كلمة المرور الخاصة بك.',
    GoToMail: 'اذهب الي البريد الإلكترونى',
    Ok: 'حسنا',
    goToHome: 'اذهب الي الصفحه الرئيسيه',
    oops: 'عفوا عنوان URLغير صحيح',
    ResendMail: 'اعاده إرسال البريد الإلكترونى',
    passwordmin: 'يجب أن تكون كلمة المرور أقل من 8 أحرف',
    passwordmax: 'يجب أن تكون كلمة المرور 20 حرفًا كحد أقصى',
    passwordexpresion: 'يجب أن تتكون كلمة المرور من 8 أحرف على الأقل بما في ذلك الأحرف الكبيرة والصغيرة.',
    profile: 'الصفحه الشخصية',
    personalInfo: 'معلومات شخصية',
    name: 'الاسم',
    nameMassage: 'برجاء إدخال الاسم',
    namePlaceholder: 'ادخل اسمك',
    Security: 'الحمايه',
    Account: 'حساب',
    Change: 'تغيير',
    Deactivate: 'تعطيل',
    DeactivateAccount: 'تعطيل الحساب',
    SaveChanges: 'حفظ التغييرات',
    ChangePassword: 'تغيير الرقم السري',
    NoCancel: 'الغاء',
    YesDeactivate: 'حسنا , قم بتعطيل الحساب',
    PasswordsChanged: 'تم تغيير الرقم السري',
    successfullyDeleted: ' تم الحذف بنجاح',
    AccountDeactivated: 'تم تعطيل الحساب',
    YourAccountDeactivated:
      'تم إلغاء تنشيط حسابك ، يرجى العلم بأنه لن تتمكن أبدًا من تسجيل الدخول مرة أخرى أو استعادة حسابك',
    Delete: 'مسح',
    AreYouSoure: 'هل انت متاكد انك تريد مسح هذه المعلومات',
    YesSave: 'نعم, احفظ',
    NoSave: 'لا تحفظ',
    Cancel: 'الغاء',
    EnterPassFor: 'برجاء إدخال الرقم السري الخاص ب',
    TimedOut:
      'لقد انتهت مهلتك بسبب الخمول. يرجى اختيار البقاء تم تسجيل الدخول أو تسجيل الخروج. خلاف ذلك ، سوف يتم تسجيلك أوتوماتيكيا بعد 1 دقيقة.',
    SinginOrLogout: 'تم تسجيل الدخول أو تسجيل الخروج',
    otherwise: 'خلاف ذلك ، سوف يتم تسجيلك أوتوماتيكيا ',
    minute: 'بعد 1 دقيقة.',
    stay: 'ابقي',
    inactivityMessage: 'لقد تم تسجيل الخروج بسبب عدم النشاط',
    Imagemustsmallerthan5MB: 'يجب أن تكون الصورة أصغر من 5 ميغابايت',
    YoucanonlyuploadJPGPNG: 'يمكنك فقط تحميل ملف JPG / PNG',
    createAccount: 'حساب جديد',
    aleadyHaveAccount: 'لديك حساب بالفعل ؟',
    signUp: 'إنشاء حساب جديد',
    profileUpdated: 'تم تعديل الحساب بنجاح',
  },
  notifi: {
    MarkAll: 'تحديد قراءه الكل',
    NotifiContent: 'مرحباً بك مرة أخرى في لوحة إدارة مختبرات',
    fiveMin: 'خمسه دقائق',
    Delete: 'مسح',
  },
  home: {
    exportExcel: 'تحميل البيانات',
  },
  header: {
    Search: 'بحث...',
    Editprofile: 'تعديل الملف الشخصي',
    SUPERADMIN: 'مدير',
    NOTIFICATIONS: 'اشعارات',
    Seeall: 'اظهار الكل',
  },
  errors: {
    Unauthorized: 'غير مصرح',
    sorry401: 'ناسف لا يمكن تنفيذ هذه العمليه',
    goToHome: 'اذهب الي الصفحه الرئيسيه',
    sorry403: 'نأسف ، لكن ليس لديك حق الوصول إلى هذه الصفحة أو المورد.',
    Access: 'غير مسموح بالدخول',
    notFound: 'لم يتم الوصول للصفحه',
    error_text: 'الصفحه التي تبحث عنها ربما تكون قد تم حذفهااو تم تغيير اسمها او غير متاحه حاليا',
    serverError: 'خطا بالخادم',
    sorry400: 'الطلب المقدم غير صحيح',
  },
  MScreens: {
    LinkExpired: 'انتهت صلاحية الرابط',
    plaeaseEnterMail: 'برجاء إدخال بريدك الإلكتروني ثم انقر فوق الزر أدناه لطلب واحد آخر.',
    email: 'البريد الإلكترونى',
    mail_massage: 'هذا البريد الإلكترونى غير صالح',
    mail_req_massage: 'برجاء إدخال بريدك الإلكترونى',
    ResendMail: 'اعاده إرسال البريد الإلكترونى',
    oops: 'عفوا عنوان URLغير صحيح',
    Ok: 'حسنا',
    PasswordReset: 'تم إعادة تعيين كلمات المرور بنجاح',
    ResetPassword: 'تعيين رقم سري جديد',
    NewPassword: 'رقم سري جديد',
    passwordMassage: 'برجاء إدخال كلمة المرور',
    passwordmin: 'يجب أن تكون كلمة المرور أقل من 8 أحرف',
    passwordmax: 'يجب أن تكون كلمة المرور 20 حرفًا كحد أقصى',
    passwordexpresion: 'يجب أن تتكون كلمة المرور من 8 أحرف على الأقل بما في ذلك الأحرف الكبيرة والصغيرة.',
    password_placeholder: 'ادخل رقمك السري',
    confirmPassword: 'تأكيد كلمة المرور الجديدة',
    confirm_massage: 'الرجاء تاكيد كلمة المرور',
    restPass_placeholder: 'اعد كتابه رقم المرور',
    cancle: 'الغاء',
    Reset: 'إعادة تعيين',
    CheckInpoxp1: 'يرجى التحقق من مجلد البريد الوارد أو البريد العشوائي في',
    CheckInpoxp2: 'للحصول على رابط لإعادة تعيين كلمة المرور الخاصة بك.',
    goToHome: 'اذهب الي الصفحه الرئيسيه',
    backToSignin: 'الرجوع الي تسجيل الدخول',
  },
  settings: {
    PasswordsChanged: 'تم تغيير الرقم السري',
    successfullyDeleted: ' تم الحذف بنجاح',
    ChangePassword: 'تغيير الرقم السري',
    NewPassword: 'رقم سري جديد',
    passwordMassage: 'برجاء إدخال كلمة المرور',
    passwordmin: 'يجب أن تكون كلمة المرور 8 أحرف على الأقل',
    passwordmax: 'يجب أن تكون كلمة المرور 20 حرفًا كحد أقصى',
    passwordexpresion: 'تعبير كلمة المرور الذي يتطلب حرفًا صغيرًا واحدًا وحرفًا كبيرًا واحدًا ورقمًا واحدًا',
    password_placeholder: 'ادخل رقمك السري',
    confirmPassword: 'تأكيد كلمة المرور الجديدة',
    confirm_massage: 'الرجاء تاكيد كلمة المرور',
    restPass_placeholder: 'اعد كتابه رقم المرور',
    logout: 'تسجيل الخروج',
    AccountDeactivated: 'تم تعطيل الحساب',
    YourAccountDeactivated: 'تم إلغاء حسابك ، يرجى العلم بأنه لن تتمكن أبدًا من تسجيل الدخول مرة أخرى أو استعادة حسابك',
    YesSave: 'نعم, احفظ',
    NoSave: 'لا تحفظ',
    Cancel: 'إلغاء',
    Delete: 'مسح',
    SaveChanges: 'حفظ التغييرات',
    AreYouSoure: 'هل تريد حفظ البيانات ؟',
    signIn: 'تسجيل الدخول',
    EnterPassFor: 'برجاء إدخال الرقم السري الخاص ب',
    password: 'الرقم السري',
    passwordMassage: 'برجاء إدخال كلمة المرور',
    passwordmin: 'يجب أن تكون كلمة المرور 8 أحرف على الأقل',
    passwordmax: 'يجب أن تكون كلمة المرور 20 حرفًا كحد أقصى',
    passwordexpresion: 'تعبير كلمة المرور الذي يتطلب حرفًا صغيرًا واحدًا وحرفًا كبيرًا واحدًا ورقمًا واحدًا',
    password_placeholder: 'ادخل رقمك السري',
  },

  setting: {
    Settings: 'الإعدادات',
    Integratedappsmasterata: 'التطبيقات المتكاملة البيانات الرئيسية',
    GoogleRECAPTCHA: 'جوجل RECAPTCHA',
    GoogleAnalyticsTrackingCode: 'جوجل تتبع تحليلات مدونة',
    FacebookAppID: 'معرف تطبيق Facebook',
    FacebookAppSecret: 'Facebook App Secret',
    LinkedInAppID: 'لينكدين معرف التطبيق',
    EmailedLinksSettings: 'إعدادات ارتباطات بالبريد الإلكتروني',
    Emailverification: 'تأكيد بواسطة البريد الإلكترونى',
    Enable: 'مكن',
    Emailverificationlinkexpirytimeminutes: 'وقت انتهاء صلاحية التحقق من البريد الإلكتروني (بالدقائق)',
    Mins: 'الدقائق',
    EmailNotifications: 'اشعارات البريد الإلكترونى',
    Resetpasslinkverificationlinkexpirytimeminutes: 'إعادة تعيين رابط انتهاء صلاحية رابط التحقق (بالدقائق)',
    Resendmailinterval: 'إعادة إرسال الفاصل الزمني للبريد',
    Tries: 'المحاولات',
    Xplacholder: 'xxxx-xxxx-xxxx',
  },

  Privileges: {
    AddRole: 'إضافة دور',
    Newrole: 'دور جديد',
    Personalinfo: 'معلومات شخصية',
    Rolename: 'اسم الدور',
    ISACTIVE: 'نشط',
    Hidetranslations: 'إخفاء الترجمات',
    TranslateIntoAr: 'ترجم إلى “عربي“',
    TranslateIntoFr: 'ترجمة إلى "اللغة الفرنسية"',
    Enable: 'مكن',
    Rolestatus: 'حالة الدور',
    Privileges: 'امتيازات',
    Privilege: 'امتيازات',
    AddPrivileges: 'إضافة الامتيازات',
    SelectPrivileges: 'اختر الامتيازات',
    PleaseselectPrivilegestoAssign: 'يرجى اختيار الامتيازات لتخصيصها',
    AssignedPrivileges: 'الامتيازات المخصصة',
    Admins: 'المسؤولين',
    AddAdmins: 'إضافة المسؤولين',
    SelectAdmins: 'حدد المسؤولين',
    PleaseselectAdminstoAssign: 'يرجى اختيار المسؤولين لتعيين',
    deletedSuccessfully: 'تم حذف الأدوار بنجاح',
    editedSuccessfully: 'تم تعديل الدور بنجاح',
    addedSuccessfully: 'تم إضافة دور جديد بنجاح',
    Showtranslations: 'إظهار الترجمة',
    Hidetranslations: 'اخفاء الترجمة',
  },

  currency: {
    Currencyname: 'اسم العملة',
    Addcurrency: 'اضف عملة',
    Newcurrency: 'عملة جديدة',
    currencyinfo: 'معلومات العملة',
  },

  users: {
    USERS: 'المستخدمين',
    Name: 'الاسم',
    Age: 'السن',
    Email: 'البريد الإلكتروني',
    Address: 'العنوان',
    Admins: 'المسؤولين',
    UpdateAdmins: 'تعديل المسؤولين',
    Adduser: 'إضافة مسؤول',
    Personalinfo: 'معلومات شخصية',
    Rules: 'الأدوار',
    Addrule: 'أضف الدور',
    Selectrule: 'اختر الدور',
    PleaseselectruletoAssign: 'يرجى اختيار دور لتعيين',
    Assignedrules: 'الأدوار المخصصة',
    deletedSuccessfully: 'تم حذف المستخدمين بنجاح',
    editedSuccessfully: 'تم تعديل المستخدم بنجاح',
    addedSuccessfully: 'تم إضافة مستخدم جديد بنجاح',
  },
  grid: {
    New: 'جديد ',
    goTo: 'الذهاب لصفحة',
    gridSearch: 'بحث',
    reset: 'إعادة تعيين',
    sureToCancel: 'هل أنت متأكد من الحذف؟',
    no: 'لا',
    ID: 'ID',
    Type: 'النوع',
    Status: 'الحالة',
  },
  buttons: {
    Close: 'أغلق',
    SaveNew: 'حفظ و جديد',
    Save: 'حفظ',
    Cancel: 'إلغاء',
    UpdateNew: 'تحديث و جديد',
    Update: 'تحديث',
    Duplicate: 'كرر',
    Delete: 'حذف',
    View: 'عرض',
    Archive: 'أرشيف',
    Edit: 'تعديل',
    SaveAddConnectorsDetails: 'حفظ وإضافة تفاصيل الموصلات',
    AddConnectorsDetails: 'إضافة الموصلات',
    ConnectorsDetails: 'تفاصيل الموصلات',
    ChargerDetails: ' تفاصيل الشاحن',
    confirm: 'هل أنت متأكد من أن تقوم بال',
    selectToEdit: 'يرجى إختيار صف للتعديل',
    selectToDelete: 'يرجى إختيار صف أو أكثر للحذف',
    change: 'تغيير',
    remove: 'حذف',
    Send: 'إرسال',
    Book: 'احجز',
    qu: '؟',
    deleteTheRest: 'حذف البقية',
    selectAll: 'إختر الكل',
    add: 'إضافة',
    YesCancel: 'نعم الغي',
    Yes: 'نعم ',
    No: 'لا',
    NoDontCancel: 'لا, لا تلغي',
    crop: 'قص',
    rotate: 'تدوير',
  },

  inputs: {
    inputrequired: 'الحقل مطلوب',
    fieldrequired: 'الحقل مطلوب',
    PleaseinputyourFirstname: 'برجاء إدخال الإسم الأول',
    PleaseinputyourMiddlename: 'برجاء إدخال اسمك الأوسط',
    PleaseinputyourLastName: 'برجاء إدخال إسم العائلة',
    Pleaseinputyourphonenumber: 'برجاء إدخال  رقم هاتفك',
    Firstname: 'الاسم الاول',
    Middlename: 'الاسم الأوسط',
    LastName: 'اسم العائلة',
    PhoneNumber: 'رقم الهاتف',
    phonemin: 'الهاتف لا يمكن أن يكون أقل من 8 أرقام',
    phonemax: 'الهاتف لا يمكن أن يتجاوز 11 رقم',
    phone: 'هاتف',
    Email: 'البريد الإلكتروني',
    TheinputisnotvalidEmail: 'الإدخال غير صالح البريد الإلكتروني',
    PleaseinputyourEmail: 'برجاء إدخال  البريد الإلكتروني ',
    EmailAlreadyExists: 'البريد الإلكترونى موجود بالفعل',
    Firstnamemustcharacters: 'يجب أن يكون الاسم  3 أحرف على الأقل',
    namecantacceptspace: 'الاسم لا يمكن أن يقبل مسافة',
    Namecanacceptspecialcharacters: 'لا يمكن أن يقبل الاسم الأحرف الخاصة',
    Namecanacceptnumbers: 'الاسم لا يمكن أن يقبل الأرقام',
    passwordDoesnotMatch: 'كلمة المرور و التأكيد غير متطابقين',
  },
  masterData: {
    basicInfo: 'البيانات الأساسية',
    label: 'البيانات الرئيسية',
    add: 'إضافة',
    name: 'الإسم',
    email: 'البريد الإلكتروني',
    value: 'القيمة',
    isActive: 'هل مفعل ؟',
    active: 'مفعل',
    inActive: 'غير مفعل',
    translate: 'الترجمة',
    hideTranslations: 'إخفاء الترجمة',
    nameMaxLength: 'الإسم يجب أن لا يتجاوز 60 حرف',
    nameTranslationMaxLength: 'ترجمة الإسم يجب أن لا تتجاوز 60 حرف',
    nameMinLength: 'الإسم يجب أن لا يقل عن 3 أحرف',
    nameTranslationMinLength: 'ترجمة الإسم يجب أن لا تقل عن 3 أحرف',
    namePlaceholder: 'ضع الإسم هنا',
    valuePlaceholder: 'ضع القيمة هنا مثال: 25',
    nameTranslationPlaceholder: 'ضع ترجمة الإسم هنا',
    deletedSuccessfully: 'تم حذف البيانات بنجاح',
    editedSuccessfully: 'تم تعديل البيانات بنجاح',
    addedSuccessfully: 'تم إضافة البيانات بنجاح',
    theFollowingData: 'البيانات التالية ',
    inUseData: 'مستخدمة بالفعل ولا يمكن حذفها',
    inUseActivated: ' مستخدمة بالفعل ولا يمكن عدم تفعيلها',
    phone: 'هاتف',
    Ban: 'حظر',
    Banned: 'محظور',
  },
  countries: {
    basicInfo: 'البيانات الأساسية',
    label: 'بلد',
    deletedSuccessfully: 'تم حذف البلاد بنجاح',
    editedSuccessfully: 'تم تعديل البلد بنجاح',
    addedSuccessfully: 'تم إضافة بلد جديد بنجاح',
    nullFlag: 'يرجى إختيار صورة',
    countryCurrency: 'عملة البلد',
    currencyPlaceholder: 'إختر عملة البلد',
    countryCodePlaceholder: 'إدخل كود البلد, مثال: +20',
    countryCode: 'كود البلد',
  },
  governorates: {
    basicInfo: 'البيانات الأساسية',
    label: 'محافظة',
    deletedSuccessfully: 'تم حذف المحافظات بنجاح',
    editedSuccessfully: 'تم تعديل المحافظة بنجاح',
    addedSuccessfully: 'تم إضافة محافظة جديدة بنجاح',
  },
  sideBar: {
    Admins: 'المديرين',
    Roles: 'الأدوار',
    Schools: 'المدارس',
    Stations: 'محطات',
    MasterData: 'البيانات الرئيسية',
    Setting: 'الإعدادات',
    countries: 'البلاد',
    Positions: 'المناصب',
    PaymentMethods: 'وسائل الدفع',
    Currency: 'العملات',
    Sectors: 'القطاعات',
    Teams: 'الفرق',
    governorates: 'المحافظات',
    Activities: 'الأنشطة',
    SickReport: 'تقرير الحالة المرضية',
    Types: 'أنواع السيارات',
    Brands: 'ماركات السيارات',
    ChargerManagement: 'محطات الشحن',
    cartype: 'أنواع السيارات',
    carbrand: 'ماركات السيارات',
    batteryrange: 'سعة البطاريات',
    Users: 'العملاء',
    Cardrequests: 'البطاقات',
    Contactusrequests: 'تواصل معنا',
    Chargerspricingsettings: 'اسعار الشحن',
    Security: 'الأمن',
    Requests: 'الطلبات',
    ChargersManagement: 'محطات الشحن',
    Sessions: 'تاريخ الجلسات',
    Bookings: 'الجلسات المحجوزة',
  },
  schools: {
    SchoolName: 'اسم المدرسة',
    Fax: 'فاكس',
    AddSchool: 'إضافة مدرسة',
    AddressLine1: 'العنوان',
    AddressLine2: 'إستكمال العنوان',
    PaymentMethod: 'وسيلة الدفع',
    Website: 'موقع الكتروني',
    SchoolEmail: 'موقع المدرسه الالكترونى',
    AllowedStudents: 'الطلاب المسموح لهم',
    ContactPersonName: 'اسم شخص الاتصال',
    pleaseEngerName: 'برجاء إدخال  الاسم',
    Position: 'مركز',
    enterPosition: 'برجاء إدخال مركزك',
    email: 'البريد الالكترونى',
    enterEmail: 'برجاء إدخال البريد الالكترونى',
    Country: 'البلد',
    State: 'المحافظة',
    selectCountery: 'اختر بلد',
    selectState: 'اختر محافظة',
    addContactPersonData: 'إضافة جهة إتصال',
    invalidURL: 'يرجى التأكد من إدخال رابط صحيح',
    schoolData: 'بيانات المدرسة',
    contactPersonData: 'بيانات جهات الإتصال',
    contactWorkPhone: 'هاتف العمل',
    contactPersonalPhone: 'الهاتف الشخصي',
    addContactModalTitle: 'إضافة جهة إتصال',
  },
  payment: {
    PaymentTypes: 'أنواع الدفع',
    PaymentHistory: 'تاريخ الدفع',
    Chooseyourpaymentplan: 'اختر خطة الدفع التي ستتبعها',
    SelectPaymenttype: 'اختر طريقة الدفع',
    Features: 'المميزات:',
    CurrentStudentsNumber: 'عدد الطلاب الحاليين:',
    student: 'طالب',
    Changesby: 'التغييرات بواسطة:',
    EffectiveDate: 'تاريخ التنفيذ',
    LastChanges: 'أخر التعديلات',
    seemore: 'المزيد',
    CurrentTotalCost: 'التكلفة الإجمالية الحالية',
    perStu: 'ج م لكل طالب',
    NumberofdaysuntileveryPayment: 'عدد الأيام حتى كل دفعة.',
    InspecificDayeverymonths: 'في يوم محدد كل شهر.',
    days: 'أيام',
    Mon: 'أشهر',
  },
  Stations: {
    Newstation: 'محطة جديدة',
    ViewStation: 'عرض المحطة',
    StationsName: 'اسم المحطات',
    NofChargers: 'عدد اجهزه الشحن',
    ChoutofService: 'خارج الخدمة',
    Governorate: 'محافظة',
    Location: 'موقع',
    AddNewStation: 'أضف محطة جديدة',
    StationName: 'اسم المحطة',
    StationNameTranslation: 'ترجمة إسم المحطة',
    PleaseInputStationName: 'برجاء ادخال اسم المحطة ',
    stationNamePlaceHolder: 'اكتب اسم المحطة',
    stationNameTranslationPlaceHolder: 'اكتب ترجمة إسم المحطة',
    NumberOfChargers: 'عدد الشواحن',
    PleaseInputNumberOfChargers: 'برجاء ادخال عدد الشواحن',
    NumberOfChargersPlaceholder: 'ادخل عدد الشواحن',
    NumberOfOutOfServiceChargers: 'عدد الشواحن خارج الخدمة',
    PleaseinputNumberOfoutOfService: 'برجاءادخال عدد الشواحن خارج الخدمة',
    outOfServiceChargersPlaceholder: 'ادخل عدد الشواحن خارج الخدمة',
    Governorate: 'المحافظة',
    Chargers: 'شاحن/شواحن',
    Available: 'متاح',
    Address: 'العنوان',
    Directions: 'الاتجاهات',
    DirectionsToStation: 'الاتجاهات إلى المحطة',
    PleaseInputGovernorate: 'برجاء ادخال المحافظة',
    stationGovernorate: 'محافظة المحطة',
    LocationOnMap: 'الموقع علي الخريطة',
    PleaseInputLocationonMap: 'برجاء تحديد الموقع علي الخريطة',
    LocationPlaceholder: 'حدد الموقع علي الخريطة',
    chargerIdPlaceholder: 'إدخال تعريف الشاحن',
    commentPlaceholder: 'إدخال تعليق على الشاحن',
    addCharger: '+ إضافة شاحن',
    chargerID: 'رقم الشاحن',
    chargerStatus: 'حالة الشاحن',
    chargerComment: 'تعليق',
    typeanycomment: 'اكتب أي تعليق تريده هنا ...',
    Tiles: 'قائمه',
    AddChargerDetails: 'أضف تفاصيل الشاحن',
    AddCharger: 'إضافة شاحن',
    AddChargerSuccessfully: 'تمت إضافة تفاصيل الشاحن بنجاح',
    ID: ' معرف إيفرون',
    Secretkey: 'مفتاح سري',
    IPAddress: ' عنوان IP',
    Connectors: ' موصلات',
    Status: ' الحالة',
    StationSerialN: ' رقم المحطة',
    Stationmodel: ' نموذج المحطة',
    StationVendor: ' محطة البيع',
    OCPPversion: ' إصدار OCPP',
    ICCID: ' ICCID',
    SIMcardN: ' بطاقة SIM',
    IMSI: ' IMSI',
    Internationalmobilesubscriberidentity: 'هوية المشترك الدولي للجوال',
    Typechargerinternational: ' اكتب هوية الشاحن الدولي للهاتف المحمول ..',
    TypechargeSIMcard: ' اكتب رقم بطاقة SIM للشاحن ..',
    TypechargerOCPPversion: ' اكتب شاحن نسخة OCPP ..',
    TypechargerStationVendor: ' اكتب شاحن محطة البائع ..',
    TypechargerStationmodel: ' اكتب طراز محطة الشحن ..',
    TypechargerStationSerialnumber: ' اكتب شاحن محطة الرقم التسلسلي ..',
    Numberofconnectors: 'يظهر عدد الموصلات وأنواع الموصلات المتاحة بالكامل',
    TypechargerIPaddress: 'اكتب عنوان IP للشاحن ..',
    Typechargersecretkey: ' اكتب مفتاح سر الشاحن ..',
    TypechargerId: 'اكتب id الشاحن ..',
    Map: ' خريطه',
    MoreInfo: 'معلومات عن',
    stationAddedSuccessfully: 'تم إضافة محطة شحن جديدة بنجاح',
    stationEditSuccessfully: 'تم تعديل محطة الشحن بنجاح',
    chargersNo: 'عدد الشواحن',
    Tiles: 'المحطات',
    all: 'الكل',
    NearMe: 'بالقرب مني',
    StationsNearYou: 'محطة شحن بالقرب منك',
    StationsResult: 'محطات شحن',
    Findtheneareststation: 'ابحث هنا عن أقرب محطة شحن لك',
    Map: 'الخريطة',
    MoreInfo: 'معلومات أكثر',
    outOfServiceChargersNo: 'الشواحن خارج الخدمة',
    governateName: 'إسم المحافظة',
    alreadyExists: 'الشاحن موجود بالفعل',
    StationInfo: 'معلومات المحطة',
    ChargersInfo: 'معلومات الشواحن',
    Edit: 'تعديل',
    Delete: 'حذف',
    Address: 'العنوان',
    UploadPhoto: 'حمل الصورة',
    Youcantchangethisfield: 'لا يمكنك تغيير هذا المجال ، هذا يعتمد على البيانات المدرجة في قسم معلومات الشواحن',
    NumberOfChargers: 'عدد الشواحن',
    NumberOfoutDutyChargers: 'عدد الشواحن الخارجة عن الخدمة',
    NearMe: 'بالقرب مني',
    ChargersEmptyState: 'عذرا ، لا توجد شواحن للعرض',
    Code: 'الكود',
    Type: 'النوع',
    Status: 'الحالة',
    Action: 'الأجراءات',
    ConnectorsEmpty: 'عذرا ، لا توجد موصلات للعرض',
  },
  Customers: {
    CustomersName: 'اسم العميل',
    Email: 'البريد الإلكتروني',
    Phone: 'الهاتف',
    CardStatus: 'حالة البطاقة',
    Lastcharge: 'اخر شحن',
    ViewProfile: 'عرض الصفحة الشخصية',
    ViewCard: 'عرض البطاقه',
    MobilePhone: 'رقم التليفون',
    Age: 'العمر',
    Gender: 'الجنس',
    CarType: 'نوع السيارة',
    BatteryRange: 'سعة البطارية',
    carlicense: 'رخصة القيادة',
    PersonalInfo: 'معلومات شخصية',
    CarInfo: '  سيارتك الكهربائية',
    Comments: 'تعليقات',
    writeyourcommenthere: 'اكتب تعليقك هنا',
    new: 'جديد',
    active: 'نشيط',
    inactive: 'غير نشط',
    inProgress: 'جاري العمل عليه',
    delivered: 'تم التوصيل',
    Requested: 'طلب جديد',
    needsAction: 'يحتاج لإجراء',
    inDelivery: 'جاري التوصيل',
    canceled: 'ملغى',
    RequestInfo: 'معلومات الطلب',
    address: 'العنوان',
    deliveryAddress: 'عنوان التوصيل',
    male: 'ذكر',
    fmale: 'انثي',
    customerBan: 'حظر العميل',
    commentSent: 'تم إرسال التعليق بنجاح',
    statusUpdated: 'تم تغيير الحاله بنجاح',
    userBaned: 'تم حظر العميل بنجاح',
    userActivated: 'تم تفعيل العميل بنجاح',
    wallet: 'المحفظة',
    sessions: 'الجلسات',
    add: 'إضافة',
    deduct: 'خصم',
    balance: 'رصيد',
    reasonLabel: 'سبب هذه العملية',
    amount: 'المبلغ',
    reasonPlaceHolder: 'إدخل سبب هذه العملية',
    amountPlaceHolderAdd: 'إدخل المبلغ المراد إضافته',
    amountPlaceHolderDeduct: 'إدخل المبلغ المراد خصمه',
    deductionCreator: 'هذا الخصم تم بواسطة',
    additionCreator: 'هذه الإضافة تمت بواسطة',
    amountRequired: 'خانة المبلغ مطلوبة',
    ammountMin: 'اقل مبلغ 1 جنية',
    reasonRequired: 'خانة سبب العملية مطلوبة',
    reasonWhiteSpace: 'خانة سبب العملية لا تقبل المسافات فقط',
    rejected: 'مرفوض',
    cardID: 'رقم البطاقة',
    referenceName: 'اسم الإشارة',
    cardInfo: 'معلومات الكارت',
    cardStatus: 'حالة الكارت',
    edit: 'تعديل',
    editCard: 'تعديل كارت',
    addCard: 'اضافة كارت',
    add: 'اضافة',
    issueDate: 'تاريخ الإصدار',
    cardIDRequired: 'رقم الكارت مطلوب',
    issueDateRequired: 'تاريخ الاصدار مطلوب',
    noMobile: 'لم يتم اضافة هاتف محمول',
  },
  PricingSettings: {
    Settings: 'الإعدادات',
    MinimumCharge: 'الحد الأدنى للشحن',
    KW: 'كيلووات',
    CurrentAC: 'التيار المتردد (AC)',
    CurrentDC: 'التيار المباشر (DC)',
    ChargersPricingUpdated: 'تم تحديث أسعار الشحن بنجاح',
  },
  infinty: {
    EVChargingpoints: 'أهلاً  بكم فى شبكة محطات شحن السيارات الكهربائية الأسرع نمواً فى مصر',
    Morethan50Stations: 'أكثر من 700 نقطة شحن Infinity حتى الآن',
    contactUsText: 'لمزيد من المعلومات حول كيفية عملنا في مصر يمكنك',
    Locateyourneareststation: 'تواصل معنا',
    EnvironmentFriendlyDrivingPleasure: 'صديقة للبيئة ، متعة القيادة',
    zero: 'صفر',
    emissionisourvision: ' القضاء علي الانبعاثات هي رؤيتنا',
    Getyour: 'يمكنك الآن تحميل تطبيقنا "InfinityEV" لتتمكن من:',
    // ChargingCard: 'بطاقة الشحن',
    ChargingCardArticle:
      'تحديد مكان اقرب شاحن وكيفية التوجه اليه,التعرف على انواع الشحن المختلفة والوضع الحالي لكل منهم,شحن سيارتك بسهولة ',
    GetYourCard: 'احصل على بطاقتك',
    Stations: 'محطة شحن',
    EVchargingstationstodate: 'محطة شحن سيارات كهربائية حتى الآن',
    chargingPoints: 'نقطة شحن',
    charginglocations: 'نقطة شحن',
    charginglocationsby2021: ' بحلول عام 2024',
    Neareststation: 'أقرب محطة',
    Setlocation: 'تحديد المكان',
    typeyourlocation: 'اكتب موقعك هنا ...',
    LocatetheNearestStation: 'حدد موقع أقرب محطة شحن Infinity',
    Findtheneareststation: 'ابحث عن أقرب محطة',
    headerFooter: {
      Home: 'الصفحة الرئيسية',
      AboutUs: 'نبذة عنا',
      MobileApp: 'تطبيق الهاتف المحمول',
      new: 'جديد',
      Howitworks: 'كيف تعمل',
      help: 'مساعدة',
      Stations: 'المحطات',
      ChargingStations: 'محطات الشحن',
      RequestCard: 'طلب بطاقة شحن',
      ContactUs: 'تواصل معنا',
      About: 'معلومات ',
      terms: 'الشروط و الأحكام',
      Additionallinks: 'روابط إضافية ',
      infintyAdress: 'F9 ، ذا لين ، محور 26 يوليو ، مدينة 6 أكتوبر',
      SignIn: 'تسجيل الدخول ',
      viewProfile: 'الصفحة الشخصية',
      Notifications: 'إشعارات',
      AllRightsReserved: 'كل الحقوق محفوظة',
    },
  },
  contactusRequests: {
    requestStatus: 'حالة الطلب',
    status: 'حالة الطلب',
    requestDetails: 'تفاصيل الطلب',
    new: 'جديد',
    closed: 'مغلق',
    close: 'غلق',
    needsAction: 'يحتاج لإجراء',
    reply: 'الرد',
    replyHint: 'هذا الرد سيذهب مباشرة للبريد الإلكتروني الخاص بالعميل',
    commentHint: 'هذا التعليق سيظهر للمديرين فقط',
    replyPlaceholder: 'اكتب ردك على الطلب هنا',
    requestUpdatedSuccessfully: 'تم تعديل حالة الطلب بنجاح',
  },
  userProfile: {
    ChangePassword: 'تغيير كلمه السر',
    NameMin: 'الاسم لا يقل عن حرفين',
    NameMax: 'الأسم يجب ألا يزيد عن 12 حرف',
    Lastname20Max: 'لا يمكن أن يتجاوز اسم العائلة 20 حرفًا',
    Firstname20Max: 'لا يمكن أن يتجاوز الاسم الأول 20 حرفًا',
    emailMin: 'البريد الإلكتروني يجب أن يكون 5 حروف أو أكثر',
    emailMax: 'البريد الإلكتروني يجب ألا يزيد عن 150 حرف',
    Name: 'الاسم',
    firstName: 'الإسم الأول',
    lastName: 'إسم العائلة',
    pleaseEnterValidmail: 'برجاء إدخال بريد الكتروني صالح',
    pleaseEntermail: 'برجاء إدخال البريد الإلكترونى',
    mail: 'البريد الإلكترونى',
    Pleaseinputyourphonenumber: 'رقم الهاتف يجب ان يكون 11 رقم',
    pleaseEnteraddress: 'برجاء إدخال العنوان',
    pleaseSelectGender: 'برجاء تحديد الجنس',
    Male: 'ذكر',
    Female: 'انثي',
    minmaxaddress: 'العنوان يجب أن يكون من 3 إلي 300 حرف',
    ChangePassword: 'تغيير الرقم السري',
    PersonalInfo: 'معلومات شخصية',
    ChangeYourPassword: 'تغيير رقمك السري',
    Uploadnewpicture: 'رفع صوره جديده',
    address: 'العنوان',
    Currentpassword: 'الرقم السري الحالي',
    Newpassword: 'الرقم السري الجديد',
    Confirmnewpassword: 'تاكيد الرقم السري',
    CarInfo: 'معلومات عن سيارتك الكهربائية',
    CarsInfo: 'معلومات السيارات الكهربائية',
    ArrivalAddress: 'عنوان التوصيل',
    sameashomeaddress: 'نفس عنوان المنزل',
    oneofourcustomers: 'إذا كنت أحد عملائنا ، فيرجى تسجيل الدخول أولاً',
    AddmoreCar: 'اضافه المزيد من السيارات',
    Selectcarbrand: 'اختر ماركة السيارة',
    Selectcartype: 'اختر نوع السيارة',
    pleaseEntercarbrand: 'برجاء إدخال ماركه السيارة',
    cartype: 'نوع السيارة',
    pleaseEntercartype: 'برجاء إدخال نوع السيارة',
    pleaseEnterbetteryrange: 'برجاء إدخال سعة البطارية',
    Selectabetteryrange: 'اختر سعة البطارية',
    selectUserCar: 'يرجى إختيار سيارة',
    Attachcarlicense: 'ارفق رخصة السيارة',
    copyofthecarlicense: 'نسخة من رخصة السيارة',
    oryoucanattachcustomsreleasecertificate: 'أو يمكنك إرفاق شهادة الإفراج الجمركي',
    Requests: 'طلبات الكروت',
    carInfoAdded: 'تم إضافة معلومات السيارة بنجاح',
    carlicenseNeed: 'يرجى إضافة رخصة السيارة',
    NoRequests: 'لا يوجد طلبات',
    youwanttocancel: 'هل انت متاكد انك تريد الغاء الطلب',
    fillTheForm: 'برجاء إدخال البيانات التالية لطلب بطاقة شحن سيارة كهربائية',
    carsUpdated: 'تم تحديث بيانات السيارات بنجاح',
    deleteCar: 'حذف سيارة',
    carDeleteConfirmation: 'هل أنت متأكد من حذف هذه السيارة ؟',
    Createddate: 'تاريخ الإنشاء',
    Status: 'الحالة',
    DeliveryAddress: 'عنوان التسليم',
    Action: 'اجراء',
    Welcome: 'مرحباً',
    Sessions: 'الجلسات',
    foraneasierexperience: 'للحصول على تجربة أسهل ، اجعل حسابك أفضل من خلال استكمال البيانات التالية.',
    Continue: 'استمر',
    Skipfornow: 'تخطي الآن',
    pleaseEntername: 'برجاء ادخال الاسم',
    noLicenceSelected: 'يرجى إضافة صور الرخصة',
    licenseFront: 'يرجى إضافة صورة الرخصة الأمامية',
    licenseBack: 'يرجى إضافة صورة الرخصة الخلفية',
    Wallet: 'المحفظة',
    EmailExists: 'البريد الإلكتروني مسجل بالفعل, لو كنت تملك هذا الحساب قم بتسجيل الدخول',
    Wallet: 'المحفظة',
    TotalBalance: 'إجمالي الرصيد',
    Chargeyourwallet: 'اشحن محفظتك',
    TransactionsHistory: 'تاريخ المعاملات',
    All: 'الكل',
    Incoming: 'واردة',
    Outcoming: 'الصادرة',
    CreditCard: 'بطاقة الائتمان',
    ThisamounthasbeentransferredviaYour: 'تم تحويل هذا المبلغ عبر حسابك',
    whichendupwith: 'التي تنتهي مع',
    ThiscredithasbeenusedforaSession: 'تم استخدام هذا الرصيد لجلسة الشحن .',
    Session: 'جلسة شحن',
    SessionDetails: 'تفاصيل الجلسة',
    Ok: 'حسنا',
    pleaseEntername: 'برجاء ادخال الاسم',
    Booking: 'الحجز',
    BookCharger: 'احجز شاحن',
    Bookinglist: 'قائمة الحجز',
    CurrentBooking: 'الحجز الحالي',
    CarBrandType: 'ماركة السيارة / النوع :',
    ChargingType: 'نوع الشحن :',
    Time: 'الوقت :',
    Duration: 'المدة الزمنية :',
    StationLocation: 'موقع المحطة :',
    theremainingtime: 'الوقت المتبقي :',
    CancelBooking: 'إلغاء الحجز',
    BookNow: 'احجز الآن',
    NoBookingsYet: 'لا حجوزات حتى الان',
    Makefirstbooking: 'قم بحجزك الأول الآن',
    AreyousureyouwanttoCancelyourbooking: 'هل أنت متأكد أنك تريد إلغاء الحجز؟',
    Rememberthatthecostofthetimebeforecancellationwillbededucted: 'تذكر أنه سيتم خصم تكلفة الوقت قبل الإلغاء',
    NoKeepBooking: 'لا ، استمر في الحجز',
    YesCancelBooking: 'نعم ، إلغاء الحجز',
    BookedCanceledSuccessfully: 'تم إلغاء الحجز بنجاح',
    Nobookingtoday: 'لا يوجد حجز اليوم',
    Bookinghistory: 'سجل الحجوزات',
    insufficientbalance: 'ليس لديك رصيد كافٍ ، يرجى إعادة شحن حسابك لتتمكن من استخدام أجهزة شحن انفينيتي',
    payNow: 'ادفع الان',
    addCard: 'اضافة كارت',
  },
  sessions: {
    Sessions: 'الجلسات',
    BookCharger: 'حجز شاحن ',
    CarBrandType: 'نوع / ماركة السيارة',
    TimeDuration: 'الوقت / المدة',
    ChargingType: 'نوع الشحن',
    KiloWatt: 'كيلووات',
    StationLocation: 'موقع المحطة',
    Pickstationonmap: 'اختر المحطة على الخريطة لتعيين موقعها هنا ..',
    PleasePickstationonmap: 'من فضلك ، حدد محطة أو تعيين موقع على الخريطة للعثور على أقرب المحطات لها',
    SelectConnectortype: 'حدد نوع الموصل',
    PleaseSelectConnectortype: 'يرجى تحديد نوع الموصل',
    SelecttimeHHMM: 'حدد الوقت: س س: د د',
    PleaseSelecttimeHHMM: 'من فضلك ، حدد وقت الحجز',
    Selectcarbrand: 'حدد نوع / نوع سيارتك',
    PleaseSelectcarbrand: 'من فضلك ، حدد سيارتك',
    findstationslocation: 'البحث عن موقع المحطات ',
    chargerSerialnumber: 'اكتب الرقم التسلسلي لشاحن المحطة..',
    ConnectorType: 'نوع الموصل',
    ConnectorStatus: 'حالة الموصل',
    Pick: 'اختيار',
    Available: 'متاح',
    Unavailable: 'غير متاح',
    FindStationsLocation: 'ابحث عن موقع المحطات ',
  },
  contactUs: {
    fullName: 'الاسم كامل',
    email: 'البريد الإلكترونى',
    RequestDetails: 'تفاصيل الطلب',
    title: 'العنوان',
    titlePlaceholder: 'إدخل عنوان الإستفسار هنا',
    typePlaceholder: 'إختر نوع الرسالة',
    inquery: 'طلب',
    suggestion: 'إقتراح',
    praise: 'مدح',
    complaint: 'شكوى',
    PleaseinputyourRequestDetails: 'برجاء إدخال تفاصيل الطلب',
    HereToHelp: 'نحن هنا للمساعدة',
    ContactUS: 'تواصل معنا',
    ContactDetails: 'تفاصيل التواصل',
    OpeningHours: 'ساعات العمل',
    Closed: 'مغلق',
    About: 'عن',
    Howitworks: 'كيف تعمل',
    ChargingStations: 'محطة الشحن',
    RequestCard: 'طلب بطاقة شحن',
    Additionallinks: 'روابط اضافية',
    AboutUs: 'عنا',
    subjectMin: 'الموضوع يجب أن يكون 20 حرف على الأقل',
    subjectMax: 'الموضوع يجب أن يكون 200 حرف بحد أقصى',
    nameMin: 'الأسم يجب أن لا يقل عن حرفين',
    nameMax: 'الأسم يجب أن لا يتجاوز 60 حرف',
    dayopen: 'الأحد  -الخميس',
    dayclose: 'الجمعة-السبت',
    callCenterTitle: `خدمة العملاء متاحة الأن - على مدار الساعة`,
    callCenterSubtitle: `هل تحتاج إلى معرفة المزيد حول الشحن مع Infinity، أو شحن المركبات الكهربائية، أو نقاط الشحن لدينا، أو التسعير والمدفوعات؟ إتصل بنا على الخط الساخن الذي يعمل على مدار 24 ساعة طول أيام الاسبوع وسنكون سعداء بمساعدتك.`,
    surveyTitle: `هل تمتلك مكانًا رئيسيًا لمحطة شحن المركبات الكهربائية؟ شاركه معنا!`,
    surveySubtitle: `هل لديك مكان أو عقار تجاري تريد استغلاله في زيادة دخلك؟ يمكنك الآن التقدم بطلب للحصول على محطة شحن للمركبات الكهربائية وتحسين قيمة الممتلكات الخاصة بك والمساهمة في مستقبل أكثر اخضرارًا من خلال إرسال طلبك على الرابط.`,
    surveyButton: 'إبدأ',
  },
  notifications: {
    loadMore: 'عرض المزيد من التنبيهات',
    notifications: 'التنبيهات',
    noNotifications: 'ليس لديك تنبيهات',
    today: 'اليوم',
  },
  aboutUs: {
    aboutUsPragraph:
      'تأسست Infinity في عام 2018 بهدف تطوير البنية التحتية لشبكة شحن السيارات الكهربائية في مصر. نحن نعمل على إنشاء أكبر شبكة شحن سيارات كهربائية ملائمة ويعتمد عليها فى مصر. Infinity EV هى جزء من شركة Infinity وهى الشركة الرائدة فى مجال الطاقة المتجددة فى مصر.',
    Exploremore: ' اكتشف المزيد',
    Ourstations: 'محطاتنا',
    OurstationsPragraph1:
      'تم تجهيز جميع محطات شحن إنفنيتى لشحن سيارتين كهربائيتين على الأقل في وقت واحد، بينما تسمح بعض المحطات بشحن ما يصل إلى 6 سيارات. تم تجهيز المحطات بشواحن مزدوجة المقبس بقدرة 22 كيلو وات وشواحن سريعة الشحن في المحطات الرئيسية، مما يوفر شحن 50 كيلو وات لشحن سيارتك الكهربائية حتى 125 كم في 30 دقيقة.',
    OurstationsPragraph2:
      'تخضع جميع المحطات لمعايير الاتحاد الأوروبي وتتصل بكابلات شاحن Type 2. كما يمكن استخدام كبل محول للإتصال بمحطة الشحن.',
    Our: '',
    Partners: 'تجدنا في',
    EVBox:
      'بالتعاون مع EVBox، الشركة الرائدة في تصنيع وتوريد شحن السيارات الكهربائية في أوروبا، تقوم Infinity بإنشاء شبكة شحن السيارات كهربائية الأسرع نموًا في مصر. نتعاون دائماً مع شركاء استراتيجيين في العديد من الصناعات لضمان أفضل بنية تحتية للمواطنين والشركات والمدن.',
  },
  howItWork: {
    How: 'كيف',
    itworks: 'تعمل',
    Start: 'وصل',
    StartStep: 'قم بتوصيل كابل الشاحن بالسيارة والمحطة.',
    Second: 'ابدأ',
    SecondStep: 'ابدأ الشحن عن طريق تمرير هاتفك.',
    Third: 'اشحن',
    ThirdStep: 'يشير ضوء LED الأزرق إلى بدءالشحن.',
    End: 'تم الشحن',
    EndStep: 'أوقف الشحن باستخدام هاتفك.',
    RequestCard: 'طلب بطاقة شحن',
    Requestcharging: 'اطلب البطاقة لتتمكن من شحن سيارتك الكهربائية فى أى محطة شحن إنفنيتى',
    Search: 'بحث',
    Searchlocation: 'ابحث هنا عن أقرب محطة شحن لك',
    termsHeaderTitle: 'الشروط و الأحكام',
    termsHeaderSubtitle: 'يرجى قراءة سياسة الخصوصية و الشروط و الأحكام',
  },
  signIn: {
    WelcomeBack: 'مرحباً بعودتك',
    Tokeepconnected: 'للدخول على حسابك برجاء تسجيل بياناتك',
    SignIn: 'تسجيل الدخول',
    SignUp: ' إنشاء حساب جديد',
    HelloFriend: 'مرحباً بك',
    personaldetails: 'للإشتراك أدخل بياناتك وابدأ رحلتك مع إنفنيتى',
    SignintoInfinity: 'تسجيل الدخول ',
    Forgotpassword: 'لا أتذكر كلمة المرور',
    haveaccount: 'ليس لديك حساب؟',
  },
  bookedSessions: {
    bookSession: 'حجز جلسة',
    type: 'النوع',
    cost: 'التكلفة',
    duration: 'الوقت / المدة',
    station: 'المحطة',
    date: 'التاريخ',
    moreDetails: 'تفاصيل أكثر',
    customerProfile: 'تفاصيل العميل',
  },
  paymentModalLocale: {
    Payment: 'الدفع',
    PaymentMessage: 'أضف المبلغ الذي تريد شحن محفظتك به',
    Amount: 'المبلغ',
    Confirm: 'تأكيد',
    ValidAmount: 'الرجاء إدخال مبلغ صالح',
    MinAmount: 'الحد الأدنى 1 جنيه مصري',
    AmountRequired: 'حقل المبلغ مطلوب',
    Ok: 'حسنا',
    PaymentCompleted: 'اكتملت عملية الدفع بنجاح',
    PaymentCanceled: 'تم إلغاء عملية الدفع',
    PaymentError: 'حدث خطأ في عملية الدفع الخاصة بك',
    YouAdded: 'لقد قمت بأضافة',
    YouHave: 'لديك',
    Egp: 'جنية',
  },
  uploader: {
    sizeExceeded: 'الحجم الأقصى للصورة يجب أن يكون 5 ميجا',
  },
};
